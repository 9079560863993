import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconCancel, IconQuestionMark, NamedLink } from '../../components';

import css from './ListingPage.module.css';

const SectionAdditionalInfo = props => {
  const { className, cancellationPolicy } = props;

  const { fullRefundDays = 3, partialRefundDays, partialRefundPercent } = cancellationPolicy || {};

  return (
    <div className={className}>
      <div className={css.additionalInfoContent}>
        <div>
          <h3 className={css.additionalInfoTitle}>
            <IconCancel />
            <FormattedMessage id="General.cancellation" />
          </h3>
          <p className={css.additionlInfoParagraph}>
            <span className={css.cancellationPolicy}>
              {fullRefundDays > 0 ? (
                <FormattedMessage
                  id="ListingPage.cancellationFull"
                  values={{
                    fullRefund: (
                      <span className={css.marketplaceColorText}>
                        <FormattedMessage id="General.fullRefund" />
                      </span>
                    ),
                    fullRefundDays: (
                      <span style={{ fontWeight: 'bold' }}>
                        <FormattedMessage
                          id="General.days"
                          values={{ numOfDays: fullRefundDays }}
                        />
                      </span>
                    ),
                  }}
                />
              ) : null}

              {partialRefundDays > 0 ? (
                <FormattedMessage
                  id="ListingPage.cancellationPartial"
                  values={{
                    partialRefund: (
                      <span className={css.marketplaceColorText}>
                        <FormattedMessage id="General.partialRefund" />
                      </span>
                    ),
                    partialRefundDays: (
                      <span style={{ fontWeight: 'bold' }}>
                        <FormattedMessage
                          id="General.days"
                          values={{ numOfDays: partialRefundDays }}
                          style={{ fontWeight: 'bold' }}
                        />
                      </span>
                    ),
                    partialRefundPercent,
                  }}
                />
              ) : null}

              <FormattedMessage id="ListingPage.cancellationFees" />
            </span>
            <span>
              {' '}
              <NamedLink
                name="HelpCenterPage"
                params={{ tab: 'your-reservations', subtab: 'cancellations' }}
                target="_blank"
                className={css.additionlInfoLink}
              >
                <FormattedMessage id="General.more" />
              </NamedLink>
            </span>
          </p>
        </div>
        <div>
          <h3 className={css.additionalInfoTitle}>
            <IconQuestionMark />
            <FormattedMessage id="General.assistance" />
          </h3>
          <p className={css.additionlInfoParagraph}>
            <FormattedMessage id="ListingPage.assistance" />
          </p>
        </div>
      </div>
      <div className={css.additionlInfoBtnWrapper}>
        <NamedLink className={css.additionlInfoButton} target="_blank" name="HelpCenterBasePage">
          <FormattedMessage id="ListingPage.getHelp" />
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionAdditionalInfo;

import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import {
  ResponsiveImage,
  Modal,
  ShareButton,
  SaveListingButton,
  AspectRatioWrapper,
} from '../../components';
import ImageCarousel from './ImageCarousel/ImageCarousel';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  const { variants } = firstImage?.attributes || {};
  const variantNames = Object.keys(variants || {});

  // We assume aspect ratio from the first image variant
  const firstImageVariant = variants?.[variantNames?.[0]];
  const { width: aspectWidth, height: aspectHeight } = firstImageVariant || {};

  const firstFiveImages = hasImages ? listing.images.slice(0, 5) : [];

  return (
    <div className={css.sectionImages}>
      <div className={css.images}>
        {hasImages ? (
          firstFiveImages.map((image, i) => {
            const { id } = image;

            return (
              <AspectRatioWrapper
                key={id?.uuid || i}
                width={aspectWidth || 1}
                height={aspectHeight || 1}
                onClick={handleViewPhotosClick}
              >
                <ResponsiveImage
                  rootClassName={css.rootForImage}
                  alt={title}
                  image={image}
                  variants={['scaled-small', 'scaled-medium', 'scaled-large']}
                />
              </AspectRatioWrapper>
            );
          })
        ) : (
          <AspectRatioWrapper width={5} height={3}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={null}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </AspectRatioWrapper>
        )}

        {viewPhotosButton}
      </div>
      <div className={css.headingButtonsMobile}>
        <ShareButton />
        <SaveListingButton listingId={listing.id.uuid} isSmall />
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionImages;

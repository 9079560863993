import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal, ReviewRating, Reviews } from '../../components';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { useDispatch } from 'react-redux';
import { fetchReviews } from './ListingPage.duck';
import { withViewport } from '../../util/uiHelpers';
import { number, shape } from 'prop-types';

import css from './ListingPage.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const MOBILE_REVIEWS_COUNT = 2;
const DESKTOP_REVIEWS_COUNT = 4;

const SectionReviews = props => {
  const {
    reviews,
    authorId,
    reviewsMeta,
    fetchReviewsError,
    viewport,
    averageRating,
    ratingCount,
  } = props;
  const { page, totalPages } = reviewsMeta;
  const dispatch = useDispatch();

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const maxPageReviews = useMemo(
    () => (viewport.width < MAX_MOBILE_SCREEN_WIDTH ? MOBILE_REVIEWS_COUNT : DESKTOP_REVIEWS_COUNT),
    [viewport.width]
  );
  const pageReviews = useMemo(() => reviews.slice(0, maxPageReviews), [reviews, maxPageReviews]);

  const reviewsError = useMemo(
    () =>
      fetchReviewsError && (
        <h2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </h2>
      ),
    [fetchReviewsError]
  );

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  const handleLoadMoreReviews = () => {
    dispatch(fetchReviews(authorId, { page: page + 1 }));
  };

  return (
    <>
      <div className={css.sectionReviews}>
        <h2 className={css.featuresTitle}>
          <FormattedMessage id="General.reviews" />
        </h2>
        {reviews.length > 0 ? (
          <>
            <Reviews reviews={pageReviews} className={css.reviewsGrid} />

            {reviews.length > maxPageReviews && (
              <button className={css.secondaryButton} onClick={() => setIsReviewModalOpen(true)}>
                <FormattedMessage id="ListingPage.showAllReviews" />
              </button>
            )}
          </>
        ) : (
          <span className={css.noReviews}>
            <FormattedMessage id="ListingPage.noReviews" />
          </span>
        )}

        {reviewsError}
      </div>

      <Modal
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        id="SectionReviews"
        isOpen={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div>
          <ReviewRating rating={averageRating} ratingCount={ratingCount} isAccented />

          {reviewsError}

          <Reviews className={css.modalReviews} reviews={isReviewModalOpen ? reviews : []} />

          {page !== totalPages && (
            <button
              className={css.secondaryButton}
              onClick={handleLoadMoreReviews}
              disabled={page === totalPages}
            >
              <FormattedMessage id="ListingPage.loadMore" />
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

SectionReviews.propTypes = {
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(SectionReviews);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AvatarLarge, AvatarMedium, UserType } from '../../components';

import css from './ListingPage.module.css';

const SectionAvatar = props => {
  const { user } = props;

  const { displayName } = user?.attributes?.profile;

  return (
    <div className={css.sectionAvatar}>
      <AvatarLarge user={user} className={css.avatarDesktop} />
      <AvatarMedium user={user} className={css.avatarMobile} />
      <div className={css.userInfo}>
        <span className={css.userName}>
          <FormattedMessage id="ListingPage.offeredBy" values={{ name: displayName }} />
        </span>
        <UserType user={user} showFullText />
      </div>
    </div>
  );
};

export default SectionAvatar;

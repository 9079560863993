import React, { useMemo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './ListingPage.module.css';
import { useConfiguration } from '../../context/configurationContext';

const SectionFeaturesMaybe = props => {
  const { publicData } = props;
  const selectedOptions = useMemo(() => publicData?.services || [], [publicData?.services]);

  const config = useConfiguration();

  if (!publicData) {
    return null;
  }

  const servicesOptions = findOptionsForSelectFilter('services', config.search.defaultFilters);

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <div className={css.featuresContent}>
        {servicesOptions.map((option, index) => (
          <span
            key={index}
            className={classNames(css.featuresLabel, {
              [css.featuresLabelSelected]: selectedOptions.includes(option.key),
            })}
          >
            <FormattedMessage id={option.label} />
          </span>
        ))}
      </div>
    </div>
  );
};

export default SectionFeaturesMaybe;

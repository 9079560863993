import classNames from 'classnames';
import { array } from 'prop-types';
import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { ButtonTabNavHorizontal, InfoTooltip } from '../../../components';
import { LANG_TO_LOCALE } from '../../../constants/locales';
import { FormattedMessage } from '../../../util/reactIntl';
import { richText } from '../../../util/richText';

import css from './SectionDescriptionMaybe.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { className, description, descriptionsByDay, selectedLanguages } = props;

  const [selectedLangTab, setSelectedLangTab] = useState(selectedLanguages?.[0]);

  const classes = classNames(css.root, className);

  const hasOnlyOneDesc = useMemo(() => Object.entries(description).length === 1, [description]);

  const daysDescriptions = useMemo(() => {
    if (!descriptionsByDay) return {};

    return Object.entries(descriptionsByDay).reduce((allDesc, desc) => {
      const [lang, dayDesc] = desc;

      if (!dayDesc) return allDesc;

      const descByDays = Object.entries(dayDesc);

      const byDayDesc = new Array(descByDays.length).fill(null);

      descByDays.forEach(([day, descForDay]) => {
        const dayAsNumber = day.match(/(\d+)/)[0];

        byDayDesc[dayAsNumber - 1] = descForDay;
      });

      allDesc[lang] = byDayDesc;

      return allDesc;
    }, {});
  }, [descriptionsByDay]);

  const availableDescForLanguages = useMemo(
    () => selectedLanguages?.filter(lang => !!(description?.[lang] || daysDescriptions[lang])),
    [daysDescriptions, description, selectedLanguages]
  );

  const tabs = useMemo(
    () =>
      availableDescForLanguages?.map(lang => ({
        text: <span>{LANG_TO_LOCALE[lang].split('-')[0].toUpperCase()}</span>,
        selected: selectedLangTab === lang,
        disabled: !availableDescForLanguages?.includes(lang),
        onClick: () => setSelectedLangTab(lang),
      })),
    [selectedLangTab, availableDescForLanguages]
  );

  useEffect(() => {
    // If the first selectedLang doesn't have description
    // change it to the first available one
    if (selectedLanguages?.[0] !== availableDescForLanguages?.[0]) {
      setSelectedLangTab(availableDescForLanguages[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDescForLanguages]);

  return (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.descriptionTitle" />
          <InfoTooltip id="ListingPage.descriptionTooltip">
            <FormattedMessage id="ListingPage.descriptionTooltip" />
          </InfoTooltip>
        </h2>
        {!hasOnlyOneDesc && (
          <ButtonTabNavHorizontal tabs={tabs} className={css.tabs} tabRootClassName={css.tab} />
        )}
      </div>
      <p className={classNames(css.description, css.mainDescription)}>
        {richText(description?.[selectedLangTab], {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      {daysDescriptions[selectedLangTab]?.map((desc, index) => {
        const day = index + 1;

        return (
          <div key={`${selectedLangTab}-${day}`}>
            <h5 className={css.descriptionDayTitle}>
              <FormattedMessage id="ListingPage.descriptionDayTitle" values={{ day }} />
            </h5>
            <p className={css.description}>
              {desc
                ? richText(desc, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                    longWordClass: css.longWord,
                  })
                : '-'}
            </p>
          </div>
        );
      })}
    </div>
  );
};

SectionDescriptionMaybe.propTypes = {
  description: object.isRequired,
  descriptionsByDay: object,
  selectedLanguages: array.isRequired,
};

export default SectionDescriptionMaybe;

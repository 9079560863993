import React, { useMemo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Carousel, ListingCardCompact } from '../../components';

import css from './ListingPage.module.css';
import { useSelector } from 'react-redux';
import { useConfiguration } from '../../context/configurationContext';

const SectionMoreListingsMaybe = props => {
  const { location, listings } = props;

  const { currentUser } = useSelector(state => state.user);
  const config = useConfiguration();

  const listingsInRegionCarousel = useMemo(
    () =>
      listings.length > 0 && (
        <Carousel
          columns={5}
          useSlider={false}
          className={css.carousel}
          contentClassName={css.carouselContent}
        >
          {listings.map((l, i) => (
            <ListingCardCompact
              key={i}
              className={css.listingCard}
              listing={l}
              currentUser={currentUser}
              config={config}
            />
          ))}
        </Carousel>
      ),
    [config, currentUser, listings]
  );

  if (!listingsInRegionCarousel) return null;

  return (
    <div className={css.listingsInRegion}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.moreListingsIn" values={{ location }} />
      </h2>
      {listingsInRegionCarousel}
    </div>
  );
};

export default SectionMoreListingsMaybe;

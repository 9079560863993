import React from 'react';
import { ReviewRating, SaveListingButton, ShareButton } from '../../components';
import { FormattedMessage } from 'react-intl';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const { richTitle, averageRating, ratingCount, listing } = props;

  return (
    <div className={css.sectionHeading}>
      <h1 className={css.title}>{richTitle}</h1>
      <div className={css.headingInfoContainer}>
        {averageRating > 0 ? (
          <ReviewRating rating={averageRating} ratingCount={ratingCount} isAccented />
        ) : (
          <div />
        )}
        <div className={css.headingButtons}>
          <ShareButton>
            <FormattedMessage id="General.share" />
          </ShareButton>
          <SaveListingButton listingId={listing.id.uuid}>
            <FormattedMessage id="General.save" />
          </SaveListingButton>
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;

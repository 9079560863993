import React, { useMemo } from 'react';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { string } from 'prop-types';
import { IconCalendar, IconGroupPeople, IconLanguages, IconPlusUser } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { convertDateToMoment } from '../../util/dates';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const {
    details: { publicData },
    intl,
  } = props;

  const config = useConfiguration();

  const { groupType, peopleAtMost, languages, duration, advancedAvailabilityPlan } = useMemo(
    () => publicData || {},
    [publicData]
  );

  const { entries } = advancedAvailabilityPlan || {};

  const hourlyDuration = useMemo(
    () =>
      duration === 1 && entries?.length
        ? entries.reduce((hours, entry) => {
            const { startTime, endTime } = entry;

            const [startHours, startMinutes] = startTime.split(':');
            const [endHours, endMinutes] = endTime.split(':');

            const startDate = convertDateToMoment()
              .hours(startHours)
              .minutes(startMinutes);
            const endDate = convertDateToMoment(startDate)
              .hours(endHours)
              .minutes(endMinutes);

            const entryInHours = endDate.diff(startDate, 'hours', true);

            return Math.min(hours, entryInHours);
          }, 30)
        : null,
    [duration, entries]
  );

  const groupTypeOptions = useMemo(
    () => findOptionsForSelectFilter('group', config.search.defaultFilters),
    [config.search.defaultFilters]
  );
  const languageOptions = useMemo(
    () => findOptionsForSelectFilter('languages', config.search.defaultFilters),
    [config.search.defaultFilters]
  );

  const { groupTypeLabelId } = useMemo(
    () => ({
      groupTypeLabelId: groupTypeOptions.find(({ key }) => key === groupType)?.label,
    }),
    [groupType, groupTypeOptions]
  );

  const selectedLanguages = useMemo(
    () =>
      languages
        ? languageOptions
            .reduce((langLabels, lang) => {
              languages.includes(lang.key) && langLabels.push(lang.label);
              return langLabels;
            }, [])
            .join(', ')
        : null,
    [languageOptions, languages]
  );

  const { groupTypeLabel, maxPeopleLabel, durationLabel } = useMemo(
    () => ({
      groupTypeLabel:
        groupTypeLabelId &&
        intl.formatMessage({
          id: groupTypeLabelId,
        }),
      maxPeopleLabel:
        peopleAtMost &&
        intl.formatMessage({ id: 'ListingPage.maxPeople' }, { people: peopleAtMost }),
      durationLabel:
        duration > 1
          ? intl.formatMessage({ id: 'ListingPage.days' }, { duration })
          : hourlyDuration
          ? intl.formatMessage({ id: 'BookingBreakdown.quantity' }, { quantity: hourlyDuration })
          : null,
    }),
    [duration, groupTypeLabelId, hourlyDuration, intl, peopleAtMost]
  );

  return (
    <div className={css.sectionDetails}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.details" />
      </h2>
      <ul className={css.detailsContent}>
        <li>
          <IconCalendar className={css.sectionDetailsIcon} />
          {durationLabel}
        </li>
        <li>
          <IconGroupPeople className={css.sectionDetailsIcon} />
          {groupTypeLabel}
        </li>
        <li>
          <IconPlusUser className={css.sectionDetailsIcon} />
          {maxPeopleLabel}
        </li>
        <li>
          <IconLanguages className={css.sectionDetailsIcon} />
          {selectedLanguages}
        </li>
      </ul>
    </div>
  );
};

SectionDetailsMaybe.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionDetailsMaybe.propTypes = {
  rootClassName: string,
  className: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SectionDetailsMaybe);
